import React from 'react';
import OnVisible from 'react-on-visible';
import { Button, Carousel as SlickCarousel, Image } from 'components';
import './styles.scss';

const Carousel = props => {
  const { data, landingPageMode } = props;
  const { primary, items } = data;
  const { title, cta_text: ctaText, cta_link: ctaLink } = primary;

  const hasTitle = title?.html;
  const hasCta = !landingPageMode && ctaText?.text && ctaLink?.url;
  const blankHeaderClass = !hasTitle && !hasCta ? 'blank-header' : '';

  const settings = {
    centerMode: true,
    centerPadding: '15%',
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerPadding: '5%',
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: '0%',
        },
      },
    ],
  };

  return (
    <OnVisible className={`carousel-slice ${blankHeaderClass}`}>
      {hasTitle && (
        <div className="carousel-slice-head">
          <div className="wrapper">
            {hasTitle && <div className="carousel-slice-head-title" dangerouslySetInnerHTML={{ __html: hasTitle }} />}
            {hasCta && <Button to={ctaLink.url}>{ctaText.text}</Button>}
          </div>
        </div>
      )}
      <SlickCarousel settings={settings}>
        {items.map(slide => {
          const {
            image,
            card_title: cardTitle,
            card_content: cardContent,
            card_cta_text: cardCtaText,
            card_cta_link: cardCtaLink,
          } = slide;

          // checks for content for conditional rendering
          const hasTextContent = cardContent?.html;
          const hasCardTitle = cardTitle?.html;
          const hasCardCta = !landingPageMode && cardCtaText?.text && cardCtaLink?.url;
          const hasCardContent = hasTextContent && hasCardTitle;

          return (
            <div className="carousel-slice-slide" key={image.url}>
              <Image image={image} />
              {hasCardContent && (
                <div className="carousel-slice-slide-content">
                  {hasCardTitle && (
                    <div className="carousel-slice-slide-title" dangerouslySetInnerHTML={{ __html: hasCardTitle }} />
                  )}
                  {hasTextContent && (
                    <div className="carousel-slice-slide-text" dangerouslySetInnerHTML={{ __html: hasTextContent }} />
                  )}
                  {hasCardCta && <Button to={cardCtaLink.url}>{ctaText.text}</Button>}
                </div>
              )}
            </div>
          );
        })}
      </SlickCarousel>
    </OnVisible>
  );
};

export default Carousel;
