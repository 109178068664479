import React from 'react';
import OnVisible from 'react-on-visible';
import { Button, Image, Link, Icon, Carousel, Video, Breadcrumbs } from 'components';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import './styles.scss';

const OneCol = props => {
  const { data, sliceIndex, location } = props;
  const { primary, items, id } = data;
  const { title, content, image, cta_text: ctaText, cta_link: ctaLink, use_parallax: useParallax, video } = primary;

  const hasTitle = title && title.text; // for no text just pic
  const titleHtml = title && title.html; // for no text just pic
  const hasContent = content && content.html; // for no text just pic
  const hasImage = image && image.url; // for one col no pic
  const hasItems = items && items.length > 1; // for carousel
  const hasCta = ctaText?.text && ctaLink?.url;

  // conditional classes
  const blankBackground = !hasImage && !hasItems ? 'blank-background' : ''; // for process page
  const noText = !hasContent && !hasTitle && !hasCta ? 'no-text' : '';
  const showArrow = sliceIndex === 0;
  const backgroundWithText = hasImage && hasTitle;
  // const firstColId = '5109685c-fcee-5fb5-aba9-c6826b48aeac';
  const textAlignClass = useParallax ? 'right-aligned' : ''; // align text left on middle
  const paralaxEffect = useParallax ? 'paralax' : ''; // apply paralax on middle

  const scrollToNext = event => {
    event.preventDefault();
    const { nextSibling } = document.getElementById(id);
    if (nextSibling) smoothScrollIntoView(nextSibling, { behavior: 'smooth', block: 'start' });
  };

  // carousel settings
  const settings = {
    autoplay: true,
    arrows: false,
    dots: false,
    fade: true,
    speed: 4000,
  };

  const hasVideo = video && video.url;

  return (
    <OnVisible
      className={`one-col ${paralaxEffect} ${blankBackground} ${noText} ${backgroundWithText ? 'image-bg' : ''}`}
      id={id}
      percent={70}
    >
      {/* if has multiple images, render carousel */}
      {hasItems && (
        <Carousel settings={settings}>
          {items.map(slide => {
            const { carousel_image: picture } = slide;
            return (
              <div key={picture.url} className="one-col-background-container">
                <Image image={picture} />
              </div>
            );
          })}
        </Carousel>
      )}

      {hasVideo && (
        <div className="one-col-background-container">
          <Video
            key={hasTitle || 'Svelte Video'}
            id={hasTitle || 'Svelte Video'}
            placeholder={image && image.videoPlaceholder && image.videoPlaceholder.childImageSharp.fixed.base64}
            src={hasVideo}
          />
        </div>
      )}

      {hasImage && !hasVideo && (
        <div className="one-col-background-container">
          <Image image={image} />
        </div>
      )}

      {(hasTitle || hasContent || hasCta || showArrow) && (
        <div className="wrapper">
          <div className={`one-col-text-container ${textAlignClass}`}>
            {/* On time is very important and needs to be aqua */}
            {hasTitle && (
              <div
                className="one-col-heading"
                dangerouslySetInnerHTML={{ __html: titleHtml.replace('on time.', '<strong>on time.</strong>') }}
              />
            )}
            {hasContent && hasContent.length > 10 && (
              <div className="one-col-content" dangerouslySetInnerHTML={{ __html: hasContent }} />
            )}
            {hasCta && (
              <div className="one-col-cta">
                <Button to={ctaLink.url} theme="light">
                  {ctaText.text}
                </Button>
              </div>
            )}
          </div>
          {showArrow && (
            <div className="one-col-discover-container">
              <Link to="#discover" className="one-col-discover-text" onClick={scrollToNext}>
                Discover
              </Link>
              <Icon icon="arrowRight" fill="#FFFFFF" width={25} height={25} title="learn more" />
            </div>
          )}
        </div>
      )}
    </OnVisible>
  );
};

export default OneCol;
