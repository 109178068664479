import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Logo, Navigation, Banner } from 'components';
import { ScrollConsumer } from '../../context/scroll';
import './styles.scss';

const navigationLinks = [
  { to: '/', text: 'Home', className: '' },
  { to: '/about-us', text: 'About Us', className: '' },
  { to: '/projects', text: 'Projects', className: '' },
  { to: '/our-process', text: 'Our Process', className: '' },
  { to: '/contact', text: 'Contact Us', className: '' },
  // { to: '/joinery', text: 'Joinery', className: '' },
  // { to: '/kitchen-design', text: 'Kitchen Design', className: '' },
];

const Header = ({ location }) => {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      prismicSettings {
        data {
          status
          text_content {
            html
            text
          }
        }
      }
    }
  `);

  const { prismicSettings } = data;
  const { data: bannerData } = prismicSettings;
  const { text_content: text, status } = bannerData;

  return (
    <ScrollConsumer>
      {consumerProps => {
        const { darkBackground, hasScrolled } = consumerProps;
        return (
          <header className={`header ${hasScrolled ? 'scrolled' : ''} ${darkBackground ? 'dark-bg' : ''}`}>
            {status && <Banner text={text} location={location} />}
            <div className="wrapper">
              <Logo theme={darkBackground ? 'light' : 'dark'} hasScrolled={hasScrolled} />
              <a href="tel:1300 380 390" className={`header-contact-number ${hasScrolled ? 'dark' : ''}`}>
                1300 380 390
              </a>
              <Navigation
                classNamePrefix="header"
                links={navigationLinks}
                className="header-nav-links"
                theme={darkBackground ? 'light' : 'dark'}
                hasScrolled={hasScrolled}
              />
            </div>
          </header>
        );
      }}
    </ScrollConsumer>
  );
};

export default Header;
