import React from 'react';
import OnVisible from 'react-on-visible';
import { Button, Link, Image } from 'components';
import './styles.scss';

const Grid = props => {
  const { data } = props;
  const { primary, items } = data;
  const { title, cta_text: ctaTextObj, cta_link: ctaLinkObj } = primary;

  const titleHtml = title?.html;
  const ctaText = ctaTextObj?.text;
  const ctaLink = ctaLinkObj?.url;
  const hasCta = ctaText && ctaLink;

  return (
    <div className="grid-slice">
      {/* text left */}
      <div className="wrapper">
        <div className="grid-slice-title">
          {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          {hasCta && (
            <Button to={ctaLink} theme="dark">
              {ctaText}
            </Button>
          )}
        </div>
        {/* image(s) right */}
        <div className="grid-slice-rows">
          {items.map(item => {
            const {
              image: itemImage,
              title: itemTitle,
              content: itemContent,
              cta_text: itemCtaTextObj,
              cta_link: itemCtaLinkObj,
            } = item;

            const itemCtaText = itemCtaTextObj?.text;
            const itemCtaLink = itemCtaLinkObj?.url;
            const hasItemCta = itemCtaText && itemCtaLink;
            return (
              <div className="grid-slice-row" key={itemTitle?.text} percent={25}>
                <OnVisible className="grid-slice-row-image">
                  <Image image={itemImage} alt={itemTitle?.text || 'Svelte Kitchens'} />
                </OnVisible>
                <OnVisible className="grid-slice-row-content">
                  {itemTitle?.text && <div dangerouslySetInnerHTML={{ __html: itemTitle?.html }} />}
                  {itemContent?.text && <div dangerouslySetInnerHTML={{ __html: itemContent?.html }} />}
                  {hasItemCta && (
                    <Button to={itemCtaLink} theme="dark">
                      {itemCtaText}
                    </Button>
                  )}
                </OnVisible>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Grid;
