import React, { useState, useEffect } from 'react';
import { Link, Logo, Hamburger } from 'components';
import './styles.scss';

const Navigation = props => {
  const { links, theme, hasScrolled } = props;
  const themeClass = theme === 'dark' ? 'light-mode' : 'dark-mode';

  const [showNav, setShowNav] = useState(false); // nav links appear
  const [bodyLocked, setBodyLock] = useState(false); // lock body while show nav = true
  const [scrollTop, setScrollTop] = useState(0); // set pixels to - from top when shownav = false

  // toggle nav open/shut
  const toggleNav = event => {
    event.preventDefault();
    setShowNav(!showNav);
  };

  // use esc key to close menu
  const handleEscKey = event => {
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setShowNav(false);
    }
  };

  // lock scroll when menu open/shownav = true
  const lockBody = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  //  unlock body when show nav false/menu closed
  const unlockBody = () => {
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  // use effect exit menu on esc keydown
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
      unlockBody();
    };
  }, []);

  // use effect when shownav updated
  useEffect(() => {
    if (showNav && !bodyLocked) lockBody();
    if (!showNav && bodyLocked) unlockBody();
  }, [showNav]);

  const hamburgerOpenClass = showNav ? 'hamburger-open' : '';

  return (
    <div className={`navigation ${hamburgerOpenClass}`}>
      <Hamburger onClick={toggleNav} active={showNav} themeClass={themeClass} hasScrolled={hasScrolled} />
      <nav className="navigation-menu">
        <section className="navigation-menu-right">
          <div className="navigation-menu-logo-group">
            <div className="navigation-menu-logo">
              <Logo type="plain" />
            </div>
            <div className="navigation-menu-logo-text">
              <div className="navigation-menu-svelte-text">
                <Logo type="svelteText" />
              </div>
              <div className="navigation-menu-kitchens-text">
                <Logo type="kitchenText" />
              </div>
            </div>
          </div>
          {/* <div className="navigation-menu-contact-info">
            <div className="navigation-menu-contact-detail">
              <Icon icon="phoneRing" fill="#FFFFFF" width={33} height={33} title="phone" />
              <span>(02) 9121 6222</span>
            </div>
            <div className="navigation-menu-contact-detail">
              <Icon icon="emailLetter" fill="#FFFFFF" width={33} height={33} title="email" />
              <span>hello@sveltekitchens.com.au</span>
            </div>
            <div className="navigation-menu-contact-detail">
              <Icon icon="mapLocation" fill="#FFFFFF" width={33} height={33} title="address" />
              <span>123 example address Sydney 0123</span>
            </div>
          </div> */}
        </section>
        <section className="navigation-menu-links">
          {links &&
            links.map((link, index) => {
              const formattedLinkText = link.text.replace(/\s+/g, '-').toLowerCase();
              const homeLinkCheck = formattedLinkText === 'home' && link.to === '#' ? 'active' : '';
              const linkCheck = formattedLinkText === link.to ? 'active' : '';

              return (
                <Link
                  key={link.text}
                  className={`navigation-menu-link ${link.className || ''} ${homeLinkCheck} ${linkCheck} ${
                    hasScrolled ? 'dark' : ''
                  }`}
                  to={link.to}
                  style={{ transitionDelay: `${index * 0.1}s` }}
                >
                  {link.text}
                </Link>
              );
            })}
        </section>
      </nav>
    </div>
  );
};

export default Navigation;
