import React from 'react';
import './styles.scss';

const Banner = ({ text, location = {} }) => {
  if (!text) return null;

  const { pathname } = location;

  if (pathname && pathname.indexOf('/finished-on-time') !== -1) return null;

  return (
    <section className="header-banner">
      <div className="wrapper">
        {text?.html && <div className="header-banner-text" dangerouslySetInnerHTML={{ __html: text?.html }} />}
      </div>
    </section>
  );
};

export default Banner;
