module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Svelte Kitchens', // Default Site Title used for SEO & PWA
  description: 'Svelte kitchens create residential kitchens of the highest standard, designed for your lifestyle.', // Default Site Decription used for SEO
  siteName: 'Svelte Kitchens', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/logos/logo-1024.jpg', // Default OpenGraph image
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Svelte', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Svelte Kitchens', // Author for schemaORGJSONLD
  themeColor: '#f0ad4e',
  backgroundColor: '#13191E',

  twitter: '', // Twitter Username
  googleTagManagerId: 'GTM-5PKP7X7',
  fbPixelId: '1877873062627372',
};
