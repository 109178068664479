import React, { useState, useEffect, createContext } from 'react';

const defaultCartContext = {
  handleBackGroundCheck: () => { },
  darkBackground: true,
  hasScrolled: false,
};

const ScrollContext = createContext(defaultCartContext);

export const ScrollProvider = ({ children }) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [darkBackground, setDarkBackground] = useState(true);
  const [visibleSliceArr, setVisibleSliceArr] = useState([]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  useEffect(() => {
    const nonEmptyArr = visibleSliceArr.filter(item => item);
    const isDarkBackground =
      !hasScrolled ||
      (nonEmptyArr &&
        nonEmptyArr[0] &&
        (nonEmptyArr[0].includes('one_col') || nonEmptyArr[0].includes('form_contact')));
    setDarkBackground(isDarkBackground);
  }, [visibleSliceArr]);

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollPosition >= 3 && !hasScrolled) {
      setHasScrolled(true);
    }
    if (scrollPosition < 3 && hasScrolled) {
      setHasScrolled(false);
    }
  };

  const handleBackGroundCheck = (isVisible, slice, index) => {
    const { slice_type: sliceType } = slice;

    const indexOfVisibleSlice = visibleSliceArr.indexOf(`${sliceType} - ${index}`);
    const clonedVisibleSlideArr = [...visibleSliceArr];

    // If item is visible  in the array set the slice type as the value
    if (isVisible && indexOfVisibleSlice === -1) {
      clonedVisibleSlideArr[index] = `${sliceType} - ${index}`;
      setVisibleSliceArr(clonedVisibleSlideArr);
    }

    // If item is NOT in the array set null slice type and index as the value
    if (!isVisible && indexOfVisibleSlice !== -1) {
      clonedVisibleSlideArr[index] = null;
      setVisibleSliceArr(clonedVisibleSlideArr);
    }
  };

  return (
    <ScrollContext.Provider value={{ ...defaultCartContext, handleBackGroundCheck, darkBackground, hasScrolled }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const ScrollConsumer = ScrollContext.Consumer;

export default ScrollContext;
