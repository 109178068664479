import React from 'react';
import OnVisible from 'react-on-visible';
import { Button, Link, Image, Icon, Video } from 'components';
// import rightArrow from '../../images/icons/right-arrow.svg';
import './styles.scss';

const TwoCols = props => {
  const { data, landingPageMode } = props;
  const { primary, items } = data;
  const { title, content, tagline, cta_text: ctaText, cta_link: ctaLink, left_align: leftAlignImage } = primary;

  const hasTitle = title && title.text;
  const hasContent = content && content.html;
  const hasTagline = tagline && tagline.text;
  const hasCta = !landingPageMode && ctaText?.text && ctaLink?.url;

  const imageClass = items.length > 1 ? 'has-multiple' : 'has-one';
  const alignmentCLass = leftAlignImage ? 'two-cols-left' : 'two-cols-right';

  return (
    <OnVisible className="two-cols" percent={40}>
      {/* text left */}
      <div className={`wrapper ${alignmentCLass}`}>
        <div className="two-cols-text-container">
          {hasTitle && <h1 className="two-cols-heading">{hasTitle}</h1>}
          {hasContent && hasContent.length > 6 && (
            <div className="two-cols-content" dangerouslySetInnerHTML={{ __html: hasContent }} />
          )}
          {hasTagline && <p className="two-cols-tagline">{hasTagline}</p>}
          {hasCta && (
            <Button to={ctaLink.url} theme="dark">
              {ctaText.text}
            </Button>
          )}
        </div>
        {/* image(s) right */}
        <div className={`two-cols-image-container ${imageClass}`}>
          {items.map(item => {
            const {
              image,
              videoPlaceholder,
              image_link: imgLink,
              image_text: imgText,
              video,
              custom_class: customClass,
            } = item;

            const hasVideo = video && video.url;
            const hasCustomClass = customClass ? customClass.text : '';

            if (hasVideo) {
              return (
                <Video
                  key={hasTitle || 'Svelte Video'}
                  id={hasTitle || 'Svelte Video'}
                  placeholder={
                    videoPlaceholder &&
                    videoPlaceholder.localFile &&
                    videoPlaceholder.localFile.childImageSharp.fixed.base64
                  }
                  src={hasVideo}
                />
              );
            }

            if (imageClass === 'has-multiple') {
              return (
                <Link to={imgLink.url} key={imgLink.url} className="two-cols-image-multiple">
                  <Image image={image} alt={imgText.text} className={hasCustomClass} />
                  <div className="two-cols-image-cta">
                    <span className="two-cols-image-cta-text">{imgText.text}</span>
                    <div className="two-cols-image-arrow">
                      <Icon icon="arrowRight" fill="#FFFFFF" width={30} height={30} title="learn more" />
                    </div>
                  </div>
                </Link>
              );
            }

            return (
              <Image
                image={image}
                alt={hasTitle || 'Svelte Kitchens'}
                key={hasTitle || 'Svelte Kitchens'}
                className={hasCustomClass}
              />
            );
          })}
        </div>
      </div>
    </OnVisible>
  );
};

export default TwoCols;
