import React from 'react';
import { Icon, FormikForm } from 'components';
import './styles.scss';

const FormContact = props => {
  const { data } = props;
  const { primary } = data;
  const {
    email,
    phone,
    shop_name: shop,
    street_number: street,
    suburb_postcode: suburb,
    opening_hours: openingHours,
  } = primary;

  return (
    <section className="form-contact">
      <div className="form-contact-container">
        <div className="wrapper">
          <h1 className="form-heading">Contact Svelte</h1>
          <section className="form-contact-grid-container">
            <div className="form-component">
              <FormikForm />
            </div>
            <section className="form-contact-details">
              <div className="form-contact-section">
                <div className="form-contact-heading">
                  <Icon icon="mapLocation" fill="#FFFFFF" width={30} height={30} title="address" />
                  <p>Showroom Address</p>
                </div>
                <p>{shop?.text}</p>
                <p>{street?.text}</p>
                <p>{suburb?.text}</p>
              </div>
              <div className="form-contact-section">
                <div className="form-contact-heading">
                  <Icon icon="phoneRing" fill="#FFFFFF" width={30} height={30} title="phone" />
                  <p>Phone</p>
                </div>
                <p>
                  <a href={`tel:${phone?.text}`}>{phone?.text}</a>
                </p>
              </div>
              {email?.text && (
                <div className="form-contact-section">
                  <div className="form-contact-heading">
                    <Icon icon="emailLetter" fill="#FFFFFF" width={30} height={30} title="email" />
                    <p>Email</p>
                  </div>
                  <p>{email?.text}</p>
                </div>
              )}
              <div className="form-contact-section">
                <div className="form-contact-heading">
                  <Icon icon="arrowRight" fill="#FFFFFF" width={30} height={30} title="Opening Hours" />
                  <p>Opening Hours</p>
                </div>
                <div className="form-contact-hours" dangerouslySetInnerHTML={{ __html: openingHours.html }} />
              </div>
              {/* <div className="form-contact-socials">
                <Icon icon="facebook" fill="#FFFFFF" width={20} height={20} title="facebook" />
                <Icon icon="instagram" fill="#FFFFFF" width={20} height={20} title="instagram" />
                <Icon icon="pinterest" fill="#FFFFFF" width={20} height={20} title="pinterest" />
              </div> */}
            </section>
          </section>
        </div>
      </div>
    </section>
  );
};

export default FormContact;
