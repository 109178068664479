import React from 'react';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Icon, Link } from 'components';
import logoWords from '../../images/logo-w-words.svg';
import './styles.scss';

const footerNavigationLinks = [
  { to: '/', text: 'Home', className: '' },
  { to: '/about-us', text: 'About Us', className: '' },
  { to: '/our-process', text: 'Our Process', className: '' },
  { to: '/contact', text: 'Contact Us', className: '' },
  // { to: 'joinery', text: 'Joinery', className: '' },
  // { to: 'kitchen-design', text: 'Kitchen Design', className: '' },
];

// function to scroll to top
const scrollToNext = event => {
  event.preventDefault();
  const pageTop = document.getElementById('5109685c-fcee-5fb5-aba9-c6826b48aeac');
  if (pageTop) smoothScrollIntoView(pageTop, { behavior: 'smooth', block: 'start' });
};

const Footer = ({ hideFooterLinks }) => (
  <footer className="footer">
    <div className="wrapper">
      <img src={logoWords} alt="svelte-logo" className="footer-logo" />
      {!hideFooterLinks && (
        <div className="footer-nav-links">
          {footerNavigationLinks.map(link => {
            return (
              <Link to={link.to} className="footer-nav-link">
                {link.text}
              </Link>
            );
          })}
        </div>
      )}
      <div className="sitemap">
        <div className="contact-and-socials">
          <a href="tel:1300 380 390" className="sitemap-contact">
            1300 380 390
          </a>
          <div className="sitemap-socials">
            <Link to="https://www.facebook.com/profile.php?id=61551103435861">
              <Icon icon="facebook" fill="#0F0F0F" width={20} height={20} title="facebook" />
            </Link>
            <Link to="https://www.instagram.com/sveltekitchens/">
              <Icon icon="instagram" fill="#0F0F0F" width={20} height={20} title="instagram" />
            </Link>
            {/* <Icon icon="pinterest" fill="#0F0F0F" width={20} height={20} title="pinterest" /> */}
          </div>
        </div>
        <span className="sitemap-legal">
          © Copyright {new Date().getFullYear()} - Svelte Kitchens - 28-30 Ace Crescent. Tuggerah NSW, 2250
        </span>
      </div>
      <div className="footer-top-container">
        <Link to="#top" className="footer-top-text" onClick={scrollToNext}>
          Top
        </Link>
        <Icon icon="arrowRight" fill="#0F0F0F" width={25} height={25} title="back to top" />
      </div>
    </div>
  </footer>
);

export default Footer;
