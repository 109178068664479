import React from 'react';
import { Button, FormikForm } from 'components';
import './styles.scss';

const TwoColsTextContent = ({ content }) => {
  return (
    <div className="two-cols-text-content">
      <div dangerouslySetInnerHTML={{ __html: content.html }} />
    </div>
  );
};

const TwoColsText = props => {
  const { data } = props;
  const { primary } = data;
  const { title, content, cta_text: ctaText, cta_link: ctaLink, add_form: addForm } = primary;

  const hasTitle = title && title.text;
  const hasContent = content && content.text;
  const hasCta = ctaText?.text && ctaLink?.url;
  const formClass = addForm ? 'with-form' : '';

  return (
    <section className={`two-cols-text ${formClass}`}>
      <div className="wrapper">
        <div className="two-cols-text-left">
          {hasTitle && <h1 className="two-cols-text-heading">{hasTitle}</h1>}
          {hasCta && !addForm && <Button to={ctaLink?.url}>{ctaText?.text}</Button>}
          {hasContent && addForm && <TwoColsTextContent content={content} />}
        </div>
        {hasContent && !addForm && <TwoColsTextContent content={content} />}
        {addForm && (
          <div className="form-component">
            <FormikForm />
          </div>
        )}
      </div>
    </section>
  );
};

export default TwoColsText;
