import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from 'components';
import './styles.scss';

const FormikForm = ({ isShort }) => {
  const [submitted, setSubmitted] = useState(false);
  const [clickedSubmit, setClickedSubmit] = useState(false);

  // thank you page redirect on submission
  useEffect(() => {
    if (submitted) {
      navigate('/thankyou');
    }
  }, [submitted]);

  // initial field values
  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    postcode: '',
    details: '',
  };

  const fields = [
    {
      type: 'text',
      name: 'first_name',
      placeholder: 'Enter your first name',
      label: 'First name',
      className: 'split-left',
    },
    {
      type: 'text',
      name: 'last_name',
      placeholder: 'Enter your last name',
      label: 'Last name',
      className: 'split-right',
    },
    { type: 'email', name: 'email', placeholder: 'Enter your email', label: 'Email Address' },
    { type: 'text', name: 'phone', placeholder: 'Enter your phone number', label: 'Phone number?' },
    { type: 'text', name: 'postcode', placeholder: 'Enter your postcode', label: 'Postcode?' },
    { type: 'text', name: 'details', placeholder: 'Tell us about your project', label: 'How can we help?' },
  ];

  // validation
  const validation = values => {
    const errors = {};

    if (!values.first_name) {
      errors.first_name = 'Name is required';
    } else if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    } else if (!values.phone) {
      errors.phone = 'Invalid phone number';
    } else if (!values.postcode) {
      errors.phone = 'Please enter valid postcode';
    } else if (!values.details) {
      errors.details = 'Please provide details about the project';
    }
    return errors;
  };

  // Encode values to query string
  const encode = data =>
    Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');

  const onSubmit = async (values, { setSubmitting }) => {
    await axios.post(
      'https://a5w3o6c2j1.execute-api.ap-southeast-2.amazonaws.com/dev?format=json',
      encode({
        ...values,
        _to: '96963f829db5140d64d412da62b19528304b701f5e154be491',
        // _bcc: '8f9e3f8299ac1e0d7cc814d27bbf923936163d13',
        // Jim's encrypted email
        _sender: 'Svelte',
        _formname: 'Contact form',
        _replyTo: values.email,
      }),
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    );
    setSubmitting(false);
    setSubmitted(true);
  };

  return (
    <section className="form-container">
      <div className="form-wrapper">
        <Formik initialValues={initialValues} validate={validation} onSubmit={onSubmit}>
          {({ isSubmitting, errors, values }) => (
            <Form>
              {fields.map((field, index) => {
                const hasError = errors[field.name] && clickedSubmit;

                if (field.showIf && values[field.showIf.field].indexOf(field.showIf.value) === -1) return null;
                // short form logic
                if (isShort) {
                  if (field.name === 'postcode') return null;
                  if (field.name === 'details') return null;
                }

                return (
                  <div className={`form-field ${field.className || ''}`}>
                    <p className="form-label">{field.label}</p>
                    <Field type={field.type} name={field.name} id={field.name} placeholder={field.placeholder} />
                    <div className={`validation-error ${hasError ? 'active' : 'inactive'}`}>
                      {hasError && <ErrorMessage name={field.name} component="div" />}
                    </div>
                  </div>
                );
              })}
              <div className="formik-form-button-container">
                <Button type="submit" disabled={isSubmitting} onClick={() => setClickedSubmit(true)} theme="light">
                  {isSubmitting ? 'Submitting' : 'Submit'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default FormikForm;
