import React from 'react';
import OnVisible from 'react-on-visible';
import { Button, Link, Image } from 'components';
import './styles.scss';

const Card = ({ image, title, uid }) => (
  <OnVisible className="card">
    <Link to={`/projects/${uid}`}>
      <Image className="card-image" image={image} />
    </Link>
    <Link to={`/projects/${uid}`} className="card-title">
      {title}
    </Link>
    <Button to={`/projects/${uid}`} theme="dark">
      View Project
    </Button>
  </OnVisible>
);

export default Card;
