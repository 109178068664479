import React from 'react';
import { Link } from 'components';
import './styles.scss';

const Button = props => {
  const { children } = props;
  return (
    <ButtonContainer {...props}>
      <span className="button-arrow button-arrow-left">
        <span className="button-arrow-shaft"></span>
      </span>
      <span className="button-main">
        <span className="button-text">{children}</span>
        <span className="button-arrow button-arrow-right">
          <span className="button-arrow-shaft"></span>
        </span>
      </span>
    </ButtonContainer>
  );
};

const ButtonContainer = ({ to, title, children, theme = 'dark', type, disabled, onClick = () => {} }) => {
  // Button
  if (type === 'button' || type === 'submit') {
    return (
      <button disabled={disabled} type={type} onClick={onClick} className={`animated-button ${theme}`}>
        {children}
      </button>
    );
  }

  // Link
  if (to) {
    return (
      <Link to={to} title={title || ''} className={`animated-button ${theme}`}>
        {children}
      </Link>
    );
  }

  // Non-clickable button
  return <span className={`animated-button ${theme}`}>{children}</span>;
};

export default Button;
