import React from 'react';
import './styles.scss';

const Wysiwyg = props => {
  const { data } = props;
  const { primary } = data;
  const { content, title } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;

  return (
    <section className="wysiwyg">
      <div className="wrapper">
        {titleHtml && <div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {contentHtml && <div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
      </div>
    </section>
  );
};

export default Wysiwyg;
