import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Button, Link } from 'components';
import './styles.scss';

const Locations = props => {
  const { data, location: slugData } = props;
  const { primary } = data;
  const { content, cta_link: ctaLink, cta_text: ctaText, title } = primary;

  const LocationsData = useStaticQuery(graphql`
    query LocationQuery {
      allPrismicPage(filter: { data: { location_page: { eq: true } } }) {
        edges {
          node {
            uid
            data {
              prismic_reference {
                text
              }
            }
          }
        }
      }
    }
  `);

  const { allPrismicPage } = LocationsData;
  const { edges: locations } = allPrismicPage;

  const generatePath = slug => {
    if (slug === 'home') return '/';
    const formmatedSlug = slug.replace(/\./g, '/');
    if (slug.indexOf('staging') !== -1) return `/dev/${formmatedSlug}/`;
    return `/${formmatedSlug}/`;
  };

  return (
    <section className="locations">
      <div className="wrapper">
        {title?.html && <div className="locations-title" dangerouslySetInnerHTML={{ __html: title?.html }} />}
        {content?.html && <div className="locations-content" dangerouslySetInnerHTML={{ __html: content?.html }} />}
        {ctaLink?.url && <Button to={ctaLink?.url}>{ctaText?.text}</Button>}
        <div className="locations-container">
          {locations.map(location => {
            const { node } = location;
            const { uid, data: locationData } = node;
            const { prismic_reference: projectName } = locationData;
            if (slugData?.pathname.includes(generatePath(uid))) return null;
            return (
              <Link className="location-link" to={generatePath(uid)}>
                {projectName?.text}
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Locations;
