import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Card, Link } from 'components';
import './styles.scss';

const ProjectsData = graphql`
  query {
    ...ProjectsQuery
  }
`;

const ProjectsGrid = ({ projects, currentProject, isOtherProjects, title }) => {
  const data = useStaticQuery(ProjectsData);
  const { allPrismicProject, allPrismicProjectCategory } = data;
  const { nodes: allProjects } = allPrismicProject;
  const { nodes: allCategories } = allPrismicProjectCategory;

  const projectsToUse = projects || allProjects;
  const limitProjects = currentProject || isOtherProjects;

  const projectsCopy = projectsToUse;
  const projectsData = limitProjects
    ? projectsCopy.filter(project => project.uid !== currentProject).slice(0, 2)
    : projectsToUse;

  if (!projectsData.length) {
    return (
      <section className={`projects-grid ${limitProjects ? 'other-projects' : ''}`}>
        <div className="wrapper">
          <span className="projects-grid-title">No projects found</span>
        </div>
      </section>
    );
  }

  return (
    <section className={`projects-grid ${limitProjects ? 'other-projects' : ''}`}>
      <div className="wrapper">
        {title && <span className="projects-grid-title">{title}</span>}
        {!limitProjects && (
          <div className="project-grid-categories">
            <Link to="/projects/">All</Link>
            {allCategories.map(category => (
              <Link to={`/projects/${category?.uid}/`}>{category?.data?.title?.text}</Link>
            ))}
          </div>
        )}
        <div className="projects-grid-container">
          {projectsData.map(project => {
            const { data: projectData, uid } = project;
            const { hero_image: image, project_title: projectTitle } = projectData;
            return <Card title={projectTitle?.text} image={image} uid={uid} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default ProjectsGrid;
