import React from 'react';
import { Footer, Header, SEO } from 'components';
import { ScrollProvider } from '../../context/scroll';
import 'typeface-rubik';
import 'typeface-noto-serif';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = props => {
  const { children, seo, location, className, hideFooterLinks } = props;

  return (
    <ScrollProvider>
      <Header location={location} />
      <main className={className || ''}>
        {seo && <SEO {...seo} />}
        {children}
      </main>
      <Footer hideFooterLinks={hideFooterLinks} />
    </ScrollProvider>
  );
};

export default Layout;
