import React from 'react';
import * as Slices from 'slices';
import VisibilitySensor from 'react-visibility-sensor';
import { ScrollConsumer } from '../../context/scroll';

const Slice = props => {
  const { data: slice, sliceIndex } = props;
  const { slice_type: sliceType } = slice;

  // Handle null slice_type prop
  if (!sliceType) {
    console.error('Please pass a slice_type from your template to the Slice component');
    return null;
  }

  // Convert slice type from snake_case to TitleCase
  const sliceName = sliceType.split('_')
    .map(item => item.charAt(0).toUpperCase() + item.substring(1))
    .join('');

  // Get the slice component
  const CustomSlice = Slices[sliceName];

  // Handle missing exported Slice
  if (!CustomSlice) {
    console.error(`Can't find Slice ${sliceName}, are you sure it exists in the slices directory?`);
  }

  // Return Slice
  return (
    <ScrollConsumer>
      {consumerProps => {
        const { handleBackGroundCheck } = consumerProps;
        return (
          <VisibilitySensor
            partialVisibility
            onChange={isVisible => handleBackGroundCheck(isVisible, slice, sliceIndex)}
          >
            <CustomSlice {...props} />
          </VisibilitySensor>
        );
      }}
    </ScrollConsumer>
  );
};

export default Slice;
