import React from 'react';
import OnVisible from 'react-on-visible';
import { Button, Carousel as SlickCarousel, Image } from 'components';
import './styles.scss';

const Testimonial = props => {
  const { data } = props;
  const { primary, items } = data;
  const { title } = primary;

  const titleHtml = title?.html;

  const settings = {
    centerMode: false,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerPadding: '0',
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: '0%',
        },
      },
    ],
  };

  return (
    <OnVisible className="testimonials-slice">
      <div className="wrapper">
        {titleHtml && <div className="testimonials-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        <SlickCarousel settings={settings}>
          {items.map(slide => {
            const { author, quote } = slide;

            // checks for content for conditional rendering
            const authorHtml = author?.html;
            const quoteHtml = quote?.html;

            return (
              <div className="testimonials-slide" key={author.text}>
                {quoteHtml && (
                  <div className="testimonials-slide-quote" dangerouslySetInnerHTML={{ __html: quoteHtml }} />
                )}
                {authorHtml && (
                  <div className="testimonials-slide-author" dangerouslySetInnerHTML={{ __html: authorHtml }} />
                )}
              </div>
            );
          })}
        </SlickCarousel>
      </div>
    </OnVisible>
  );
};

export default Testimonial;
