import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const Link = props => {
  const { children, className, to, title, style, target = '_blank', onClick = () => {} } = props;
  const isExternal = (to && to.indexOf('http') !== -1) || (to && to.indexOf('mailto:') !== -1) || (to && to[0] === '#');
  if (isExternal) {
    return (
      <a
        href={to}
        className={className || ''}
        title={title || null}
        target={target}
        onClick={onClick}
        style={style}
        rel="nofollow noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  return (
    <GatsbyLink
      to={to}
      className={className || ''}
      activeClassName="active"
      title={title || null}
      onClick={onClick}
      style={style}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
