import React from 'react';
import { Image, Button } from 'components';
import './styles.scss';

const TwoColsImage = props => {
  const { data, landingPageMode } = props;
  const { items } = data;

  return (
    <section className="two-cols-image">
      <div className="wrapper">
        {items.map(item => {
          const { image, cta_text: ctaText, cta_link: ctaLink } = item;
          const hasCta = !landingPageMode && ctaText && ctaLink;
          return (
            <div className="two-cols-image-column">
              <Image image={image} className="two-cols-image-img-container" />
              {hasCta && (
                <Button to={ctaLink.url} theme="dark" className="two-cols-image-cta-button">
                  {ctaText.text}
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default TwoColsImage;
