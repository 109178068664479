import React from 'react';
import OnVisible from 'react-on-visible';
import { Video } from 'components';
import './styles.scss';

const VideoSlice = props => {
  const { data } = props;
  const { primary } = data;
  const { video, video_placeholder: placeholder } = primary;

  const hasVideo = video && video.url;

  if (!hasVideo) return null;

  return (
    <OnVisible className="video-section" percent={40}>
      <div className="wrapper">
        <Video
          key="Svelte Process"
          id="svelte-process"
          placeholder={placeholder && placeholder.localFile && placeholder.localFile.childImageSharp.fixed.base64}
          src={hasVideo}
        />
      </div>
    </OnVisible>
  );
};

export default VideoSlice;
