import React, { useState } from 'react';
import { Image, Link } from 'components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './styles.scss';

const ImageSlice = props => {
  const { data } = props;
  const { items } = data;

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  if (!items || items.length === 0) return null;

  const columnVariant = items.length > 1 ? 'two-cols-variant' : '';

  const images = items.map(item => item?.image?.url);

  const handleOpen = (event, index) => {
    event.preventDefault();
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <section className={`image-slice ${columnVariant}`}>
      <div className="wrapper">
        {columnVariant === 'two-cols-variant' ? (
          <div className="two-cols-img-container">
            {items.map((item, index) => (
              <a href="#open" className="image-slice-image" onClick={event => handleOpen(event, index)}>
                <Image image={item?.image} key={item?.image?.url} />
              </a>
            ))}
          </div>
        ) : (
          <Image className="image-slice-image" image={items[0]?.image} />
        )}
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </section>
  );
};

export default ImageSlice;
